import { withoutTokenPost } from "../../Common/HttpRestServices";
import { crmApiBaseUrl, cusFeedBack, encodeGetParams } from "../../environment/apiManage";

export const feedBackArray = () => {
    return [
        {
            language: "Tamil",
            heading: "வாடிக்கையாளர் கருத்துப் படிவம்",
            ques: [
                {
                    name: "price_offers",
                    quesText: `எங்கள் முகவர் உங்களுக்கு பொருட்களின் விலை, சலுகைகள், மற்றும் பொருட்களின் இருப்பு பற்றிய தெளிவான தகவல்களை தந்து உதவினாரா ?`,
                    opt1: "ஆம்", opt2: "இல்லை",
                    yes: "Yes", no: "No"
                },
                {
                    name: "inform_EMI", quesText: `எங்கள் முகவர் உங்களுக்கு குறைவான விலையில் சுலப மாதத்தவனை திட்டத்தை(EMI) பற்றிய தகவலை தெரிவித்தாரா ?`,
                    opt1: "ஆம்", opt2: "இல்லை",
                    yes: "Yes", no: "No"
                },
                {
                    name: "find_it_easy", quesText: `உங்களுக்கு சரியான தகவல்களை பெற எளிதாக இருந்ததா ?`,
                    opt1: "ஆம்", opt2: "இல்லை",
                    yes: "Yes", no: "No"
                },
            ],
            rating: "உங்கள் ஒட்டுமொத்த அழைப்பு அனுபவத்தை எப்படி மதிப்பிடுவீர்கள்?",
            remarkHeading: "குறிப்புகள்",
            actionButton: "சமர்ப்பிக்க"
        },
        {
            language: "English",
            heading: "CUSTOMER FEEDBACK FORM",
            ques: [
                {
                    name: "price_offers",
                    quesText: `Did our Agent help you with clear and simple information about the Product's Price, Offers and Availability?`,
                    opt1: "yes", opt2: "No",
                    yes: "Yes", no: "No"
                },
                {
                    name: "inform_EMI", quesText: `Did our Agent provide you with information about the affordable EMI's?`,
                    opt1: "yes", opt2: "No",
                    yes: "Yes", no: "No"
                },
                {
                    name: "find_it_easy", quesText: `Did you find it easy to get the right information?`,
                    opt1: "yes", opt2: "No",
                    yes: "Yes", no: "No"
                },
            ],
            rating: "How would you rate your overall call experience?",
            remarkHeading: "Remarks/Notes",
            actionButton: "Submit"
        },
        {
            language: "ಕನ್ನಡ",
            heading: "ಗ್ರಾಹಕರ ಪ್ರತಿಕ್ರಿಯೆ ಫಾರ್ಮ್",
            ques: [
                {
                    name: "price_offers",
                    quesText: `ನಮ್ಮ ಏಜೆಂಟ್ ನಿಮಗೆ ಸ್ಪಷ್ಟ ಮತ್ತು ಸರಳವಾಗಿ ಉತ್ಪನ್ನದ ಬೆಲೆ, ಕೊಡುಗೆಗಳು ಮತ್ತು ಅದರ ಲಭ್ಯತೆಯ ಬಗ್ಗೆ ಮಾಹಿತಿ ನೀಡಿದ್ದಾರಾ?`,
                    opt1: "ಹೌದು", opt2: "ಇಲ್ಲ",
                    yes: "Yes", no: "No"
                },
                {
                    name: "inform_EMI", quesText: `ನಮ್ಮ ಏಜೆಂಟ್ ನಿಮಗೆ EMI ಗಳ ಬಗ್ಗೆ ಮಾಹಿತಿ ನೀಡಿದ್ದಾರಾ?`,
                    opt1: "ಹೌದು", opt2: "ಇಲ್ಲ",
                    yes: "Yes", no: "No"
                },
                {
                    name: "find_it_easy", quesText: `ನಿಮಗೆ ಬೇಕಾದ ಮಾಹಿತಿ ಸರಳವಾಗಿ ದೊರೆಯಿತೇ?`,
                    opt1: "ಹೌದು", opt2: "ಇಲ್ಲ",
                    yes: "Yes", no: "No"
                },
            ],
            rating: "ನಿಮ್ಮ ಒಟ್ಟಾರೆ ಕರೆ ಅನುಭವವನ್ನು ನೀವು ಹೇಗೆ ರೇಟ್ ಮಾಡುತ್ತೀರಿ?",
            remarkHeading: "ಟೀಕೆಗಳು/ಟಿಪ್ಪಣಿಗಳು",
            actionButton: "ಸಲ್ಲಿಸಿ"
        },
        {
            language: "Hindi",
            heading: "ग्राहक प्रतिक्रिया प्रपत्र",
            ques: [
                {
                    name: "price_offers", quesText: `क्या हमारे एजेंट ने आपको उत्पाद की कीमत, ऑफ़र और उपलब्धता के बारे में स्पष्ट और सरल जानकारी देने में मदद की ?`,
                    opt1: "हाँ", opt2: "नहीं",
                    yes: "Yes", no: "No"
                },
                {
                    name: "inform_EMI", quesText: `क्या हमारे एजेंट ने आपको किफायती ईएमआई के बारे में जानकारी प्रदान की?`,
                    opt1: "हाँ", opt2: "नहीं",
                    yes: "Yes", no: "No"
                },
                {
                    name: "find_it_easy", quesText: `क्या आपको सही जानकारी प्राप्त करना आसान लगा ?`,
                    opt1: "हाँ", opt2: "नहीं",
                    yes: "Yes", no: "No"
                },
            ],
            rating: "आप अपने समग्र कॉल अनुभव का मूल्यांकन कैसे करेंगे ?",
            remarkHeading: "टिप्पणियाँ",
            actionButton: "जमा करें"
        }
    ];
};

export const submitForm = (ele = {}, urlEle = {}, languageInfo = {}) => {
    const {
        find_it_easy = "",
        inform_EMI = "",
        price_offers = "",
        rating = "",
        remark = "",
    } = ele;

    const newObj = {
        "status": urlEle["status"] || "",
        "ticketId": urlEle["?ticketId"] || "",
        "currentStatus": urlEle["currentStatus"] || "",
    };
    const params = {
        "notify": "N",
        "notes": remark,
        "rating": rating,
        "inform_EMI": inform_EMI,
        "find_it_easy": find_it_easy,
        "price_offers": price_offers,
        "language": languageInfo?.language,
    };

    const header = {
        "Content-Type": "application/json",
        "Authorization": "7e3R9-c9Y4T-6WQ1-4Jd8-89beS-g4Y2I"
    };
    const queryString = encodeGetParams(newObj);
    return withoutTokenPost(`${crmApiBaseUrl}${cusFeedBack.customerFeedBack}?${queryString}`, params, header, "crmToken");
};
