import React from "react";
import CustomerForm from "./Component/CustomerFeedback/CustomerForm";

function App() {
  return (
    <div >
      <CustomerForm />
    </div>
  );
}

export default React.memo(App);
