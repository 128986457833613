import { _get } from "./lodashConst";

export const manualDownloadUrlBased = (url = "", name = "") => {
    const link = document && document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document && document.body.appendChild(link);
    link.click();
};

/**
 * convert lower case
 * @param string
 */
export const convertToLowerCase = (convertValue = "") => {
    return ("" + convertValue).toLowerCase();
};

/**
 * convert string
 * @param string
 */
export const convertToString = (convertValue = "") => {
    return ("" + convertValue);
};

/**
 * check is null or not ,is null mean retun obj
 * @param obj
 */
export const nullToObj = (obj = null) => {
    return obj ? obj : {};
};

/**
 * check is null or string
 * @param str
 */
export const nullToString = (str = null) => {
    return str ? ("" + str) : "";
};

/**
 * check is null or not ,is null mean retun obj
 * @param obj
 */
export const nullToArray = (array = null) => {
    return array ? array : [];
};

/**
 * @param value excel validation
 */
export const excelFileValidate = (value = "") => {
    const allowFormet = ["csv"];
    const extension = value.split(".").pop();
    return allowFormet.includes(extension);
};

/**
 * mobile number validate
 */
export const mobileNumValidate = (number = "") => {
    if (("" + number).length === 10) {
        return true;
    }
    return false;
};

/**
 * @param  {} email=""
 * email validate
 */
export const EmailValidate = (email = "") => {
    const reg =
        /^\s*([\w+-]+\.)*[\w+]+@([\w+-]+\.)*([\w+-]+\.[a-zA-Z]{2,8})+\s*$/;
    if (!reg.test(email)) {
        return false;
    }
    return true;
};

/**
 * @param email
 * poorvika email validate
 */
export const EmailValidatePoorvika = (email = "") => {
    const reg = /^\s*([\w+-]+\.)*[\w+]+@([\w+-]+\.)*([\w+-]+\.[a-zA-Z]{2,4})+\s*$/;
    if (!reg.test(email)) {
        return false;
    }
    if (email.indexOf("@poorvika.com", email.length - "@poorvika.com".length) !== -1) {
        return true;
    }
    return false;
};

/**
 * @param  {string} password
 * password allow user stories format
 * 1 Cap char
 * 1 Small Char
 * 1 Number
 * 8 digit Must
 */
export const passwordValidate = (password = "") => {
    const reg = /^(?=.*\d)(?=.*['()+,.\/:;<>=?\[\]\-_`{}|~!@#$%^&"*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (reg.test(password)) {
        return true;
    }
    return false;
};

export const validateForm = (errors = {}) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
        if (val !== true) {
            valid = false;
        }
    });
    return valid;
};

export const negativeValidateForm = (errors = {}) => {
    let valid = false;
    Object.values(errors).forEach((val) => {
        if (val !== false) {
            valid = true;
        }
    });
    return valid;
};

/**
 * validate object with empty message
 * @param errors object
 * @returns array
 */
export const validateObjectWithMessage = (errors = {}) => {
    const valid = [];
    Object.keys(errors).forEach((val) => {
        if (val === "emailId" && !EmailValidate(errors[val])) {
            valid.push(val);
        } else if (
            typeof errors[val] === "undefined" ||
            errors[val] === "" ||
            errors[val] === null ||
            !errors[val]
        ) {
            valid.push(val);
        }
    });
    return valid.length ? valid.join(" , ") : false;
};

export const emptyValidateForm = (errors = {}) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
        if (val === "") {
            valid = false;
        }
    });
    return valid;
};

/**
 * input filed not empty hide error msg
 */
export const findEmptyOrNotLoginInput = (value = "") => {
    return value ? true : false;
};

/**
 * error msg handle input
 */
export const emptyInputErrorMsgThrow = (value = "") => {
    return value ? false : true;
};

/**
 * @param  {object} event
 * input allow only number
 */
export const numOnlyAllow = (event = {}) => {
    if (!(/[0-9/.]/).test(event.key)) {
        event && event.preventDefault && event.preventDefault();
    }
};

/**
 * @param  {object} event
 * input allow only letter
 */
export const alphaOnly = (event = {}) => {
    const inputValue = event.which;
    // allow letters and whitespaces only.
    if (
        !(inputValue >= 65 && inputValue <= 122) &&
        inputValue !== 32 &&
        inputValue !== 0
    ) {
        event.preventDefault();
    }
};

export const phoneNumValidate = (value = "") => {
    return _get(value, "length", 0) === 10;
};

export const userNameLength = (value = "") => {
    return _get(value, "length", 0) <= 100 && _get(value, "length", 0) >= 1;
};

export const subjectLength = (value = "") => {
    return _get(value, "length", 0) <= 250 && _get(value, "length", 0) >= 1;
};

export const discriptionLength = (value = "") => {
    return _get(value, "length", 0) <= 2000 && _get(value, "length", 0) >= 1;
};

export const errValidateCommon = (stateValue = "", defaultValue = false) => {
    return stateValue ? !defaultValue : false;
};

export const checkWhiteSpaces = (value = "") => {
    if (typeof value !== "string") {
        // check if the string variable is some type other than string
        return false;
    } else {
        return value.trim().length > 0;
    }
};

/**
 * @param  {string} value
 * Remove White space
 * lead and trail
 */
export const leadingAndTrailingSpaceAvoid = (value = "") => {
    const removeExtraSpace = (s) => (s || "").trim().split(/ +/).join(" ");
    return removeExtraSpace(value);
};

/**
 * @param  {string} number
 * number must be 10
 */
export const emptyValidate = (value = "") => {
    if (nullToString(value).length >= 1) {
        return true;
    }
    return false;
};

/**
 * @param  {string} number
 * dynamic length check
 */
export const dynamiCLengthValiDate = (value = "", lengthData = 0) => {
    if (("" + value).length >= lengthData) {
        return true;
    }
    return false;
};

/**
 * @param  {string} value
 * doc check
 */
export const docValidate = (value = "") => {
    const allowFormet = [
        "doc",
        "docx",
        "pdf",
        "odt",
        "txt",
        "xlsx",
        "xls",
        "csv",
    ];
    const extension = value.split("/").pop();
    return allowFormet.includes(extension);
};

/**
 * @param  {string} value
 * doc check
 */
export const getDocType = (value = "") => {
    const allowFormet = [
        "doc",
        "docx",
        "pdf",
        "odt",
        "txt",
        "xlsx",
        "xls",
        "csv",
    ];
    const extension = value.split("/").pop();
    return allowFormet.includes(extension) ? extension : "";
};

/**
 * @param  {string} value
 * image type check
 */
export const imageTypeCheck = (value = "") => {
    const allowFormet = ["jpg", "jpeg", "png"];
    const extension = value.split("/").pop();
    return allowFormet.includes(extension);
};

/**
 * @param  {string} value=""
 * @param  {string} stringLength=1000 Default value is 1000
 * text length is moreThen fixed place disPlay like ...
 */
export const titleToast = (value = "", stringLength = 100000000) => {
    if (nullToString(value) !== "") {
        return value.toString().length > stringLength
            ? value.substring(0, stringLength).concat("...")
            : value;
    }
    return "";
};

/**
 * @param  {string} number
 * ToDate convert
 */
export const dateToString = (value = "") => {
    try {
        return (
            value &&
            "" +
            new Date(value).getDate() +
            "/" +
            (new Date(value).getMonth() + 1) +
            "/" +
            new Date(value).getFullYear()
        );
    } catch {
        return false;
    }
};

export const diffColorChange = (value = 0) => {
    const commonStyle = " px-2 py-1 text-white rounded-full font-bold whitespace-nowrap mx-auto ";
    if (value > 10) {
        return `${commonStyle} bg-red-500 `;
    } else if (value >= 6) {
        return `${commonStyle} bg-yellow-500 `;
    } else if (value < 6) {
        return `${commonStyle} bg-green-500 `;
    }
    return `${commonStyle} bg-blue-500 `;
};

/**
 * @param  {string} value=""
 * enter value has been change upperCase
 * first letter convert to upperCase
 */
export const firstLetterUpperCase = (value = "") => {
    return (value || "").split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

/**
 * number to currency convert
 * @param number
 */
export const numberToCurrencyConvert = (number = 0) => {
    const convertNum = +number;
    return new Intl.NumberFormat('en-IN', { style: "currency", currency: "INR" }).format(convertNum);
};

/**
 * convert paisa to amt
 * @param value
 */
export const paisaToAmount = (value = "") => {
    let passData = value.toString().slice(0, -2);
    passData = passData.toString();
    let lastThree = passData.substring(passData.length - 3);
    const otherNumbers = passData.substring(0, passData.length - 3);
    if (otherNumbers !== '')
        lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};

/**
 * @param  {object} event
 * input allow only number
 */
export const processInput = (e = {}) => {
    const value = _get(e, "target.value", "");
    const numbers = value.replace(/[^0-9]/g, "");
    e.target.value = numbers;
};

/**
 * @param  {object} event
 * input allow only number and char
 */
export const allowOnlyNumAndChar = (e = {}) => {
    const value = _get(e, "target.value", "");
    const numbers = value.replace(/[a-zA-Z0-9]/g, "");
    e.target.value = numbers;
};

/**
 * @param  {object} event
 * input allow char
 */
export const allowOnlyChar = (e = {}) => {
    const value = _get(e, "target.value", "");
    const char = value.replace(/[^a-zA-Z ]/g, "");
    e.target.value = char;
};

/**
 * @param  {Array} event
 * remove undefined value
 */
export const arrayUndefinedRemoved = (arrayData = []) => {
    return arrayData.filter((ele) => ele !== undefined);
};

/**
 * @param  {Array} event
 * upload size handle
 */
export const fileUploadSizeValidate = (size = 0, uploadSize = 0) => {
    if (!(size > uploadSize)) {
        return true;
    }
    return false;
};
