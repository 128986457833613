import React, { useState, useEffect } from 'react';
import "./CustomerFeedback.scss";
import { submitForm } from './helperFeedBack';
import { Rating } from 'react-simple-star-rating';
import CommonRadioBox from '../../Common/CommonRadioBox';
import CommonTextarea from '../../Common/CommonTextarea';
import { convertToLowerCase, nullToObj } from '../../utils/validation';
import { failToast, succToast } from '../../Common/ToastServices/ToastDisplay';
import {
    gifHappyLite, gifSad, gifStar1, gifStar2,
    gifStar3, gifStar4, gifStar6
} from '../../assets/images';
// import { feedBackArray } from './helperFeedBack';

function FormTemplate(_props = {}) {
    const { state = 0, languageInfo = {}, getUrlPoint = {} } = _props;

    const [getFormData, setFormData] = useState({
        "remark": "",
        "language": "",
        "inform_EMI": "",
        "price_offers": "",
        "find_it_easy": "",
        "rating": 1,
    });

    const onCheckBoxChange = (name = "", value = "") => {
        setFormData({
            ...getFormData,
            [name]: value
        });
    };

    const onChangeRemark = (e) => {
        const { name = "", value = "" } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    };

    // Catch Rating value
    const handleRating = (rate = 0) => {
        setFormData({
            ...getFormData,
            rating: rate
        });
    };

    const onSubmitForm = async () => {
        const response = await submitForm(getFormData, getUrlPoint, languageInfo);
        const { data: { status = 0, message = "" } = {} } = nullToObj(response);
        if (status >= 200 && status <= 202) {
            setFormData({
                "remark": "",
                "language": "",
                "inform_EMI": "",
                "price_offers": "",
                "find_it_easy": "",
                "rating": 1,
            });
            succToast(message);
        } else {
            failToast(message);
        }
    };

    useEffect(() => {
        setFormData({
            "remark": "",
            "language": "",
            "inform_EMI": "",
            "price_offers": "",
            "find_it_easy": "",
            "rating": 1,
        });
    }, [state]);

    return (
        <div className="p-8">
            <h1 className='text-center font-bold text-xl mb-5 text-yellow-400 '>{languageInfo.heading}</h1>
            <span className='w-28 bg-yellow-400 h-1 mx-auto mb-10 table'></span>
            {languageInfo?.ques.map((item, index) => {
                const { quesText = "", yes = "", no = "", opt1 = "", opt2 = "", name = "" } = item;
                return (
                    <div key={convertToLowerCase(index + "formDatyaArry-fsvx")} className='flex flex-wrap my-5 items-center'>
                        <p className='w-full sm:w-7/12 text-sm sm:mb-0 mb-2'>{quesText}</p>
                        <div className='flex w-full sm:w-4/12 pl-5 justify-between mt-3'>
                            <div className='flex w-48'>
                                <CommonRadioBox
                                    label={true}
                                    name={name}
                                    labelText={opt1}
                                    id={`${name}-${index}`}
                                    parentClass={"ml-3 p-2"}
                                    value={getFormData[name]}
                                    checked={getFormData[name] === yes}
                                    onChange={() => onCheckBoxChange(name, yes)}
                                />
                                <CommonRadioBox
                                    id={name}
                                    label={true}
                                    name={name}
                                    labelText={opt2}
                                    parentClass={"ml-3 p-2"}
                                    value={getFormData[name]}
                                    checked={getFormData[name] === no}
                                    onChange={() => onCheckBoxChange(name, no)}
                                />
                            </div>
                            <div className='flex items-center h-10 flex-shrink-0'>
                                {getFormData[name] === opt1 ?
                                    <img className='w-10 h-10 ' src={gifHappyLite} alt='yes' />
                                    : null} {getFormData[name] === opt2 ?
                                        <img className='w-10 h-10 ' src={gifSad} alt='no' /> : null
                                }
                            </div>
                        </div>
                    </div>
                );
            })}

            <div className='flex flex-wrap my-5 items-center'>
                <p className='w-full sm:w-7/12 text-sm sm:mb-0 mb-2'>
                    {languageInfo.rating}
                </p>
                <div className='flex w-full sm:w-4/12 pl-5 justify-between rating_star_wraper'>
                    <Rating
                        onClick={handleRating}
                        disableFillHover={false}
                        allowHover={false}
                        className='rating_star flex ml-4'
                    />
                    <div className='flex items-center h-10 ml-9 flex-shrink-0'>
                        {getFormData.rating === 1 ?
                            <img className='w-10 h-10 flex-shrink-0'
                                src={gifStar1} alt='star' /> : null
                        }

                        {getFormData.rating === 2 ?
                            <img className='w-10 h-10 flex-shrink-0'
                                src={gifStar2} alt='star' /> : null
                        }

                        {getFormData.rating === 3 ?
                            <img className='w-10 h-10 flex-shrink-0'
                                src={gifStar3} alt='star' /> : null
                        }

                        {getFormData.rating === 4 ?
                            <img className='w-10 h-10 flex-shrink-0'
                                src={gifStar4} alt='star' /> : null
                        }

                        {getFormData.rating === 5 ?
                            <img className='w-10 h-10 flex-shrink-0'
                                src={gifStar6} alt='star' /> : null
                        }
                    </div>
                </div>
            </div>
            <div className=' my-5 items-center'>
                <div className='flex items-center'>
                    <p className='w-full text-sm'>{languageInfo.remarkHeading}</p>
                </div>
                <div>
                    <CommonTextarea
                        name="remark"
                        setShow={true}
                        labelText={""}
                        placeholder={""}
                        labelTextClass={"font-bold"}
                        inputClass="flex justify-end"
                        value={getFormData[`remark`]}
                        onChange={(e) => onChangeRemark(e)}
                        parentClass={"mb-4 sm:mb-8 w-full mt-4"}
                        inputFieldClass="h-20 max-h-64 py-2 resize-none bg-yellow-50"
                    />
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <button
                    onClick={() => onSubmitForm()}
                    className='bg-yellow-500 hover:bg-yellow-600 text-white
                    px-3 h-8 rounded bg-primary-gradient-hover
                    bg-primary-gradient my-2 mx-1 font-bold text-sm' type='button'
                >
                    {languageInfo.actionButton}
                </button>
            </div>
        </div>
    );
}

export default FormTemplate;
