import React, { useState } from 'react';
import "./CustomerFeedback.scss";
import FormTemplate from './FormTemplate';
import { useLocation } from "react-router-dom";
import { feedBackArray } from './helperFeedBack';
import { ImgFeedback, ImgLogoNew } from '../../assets/images';

const getQueryParams = (query = null) => (query || window.location.search.replace('?', '')).split('&').map((e) => e.split('=').map(decodeURIComponent)).reduce((r, [k, v]) => (r[k] = v, r), {});

const CustomerFeedback = () => {
  const [getTabs, setTabs] = useState(1);
  const search = useLocation();
  const getUrlPoint = getQueryParams(search?.search || "") || {};
  const FormData = feedBackArray();

  const [languageInfo, set_languageInfo] = useState({ ...FormData[1] });

  const handleTabs = (state = "") => {
    setTabs(state);
    set_languageInfo({ ...FormData[state] });
  };

  return (
    <div className="overflow-auto max-h-screen">
      <div className='customer_feedback_wraper p-5 relative px-5'>
        <div className='flex justify-center items-center mb-5 '>
          <img src={ImgLogoNew} alt="logo" width={200} />
        </div>
        <img src={ImgFeedback} alt="bg" className='fixed left-0 top-0 w-full h-full object-cover z-0 opacity-10' />
        <div className='z-10  relative max-w-5xl mx-auto rounded-md shadow bg-white bg-opacity-30 overflow-hidden '>
          <div className='flex   max-w-5xl mx-auto relative z-10 bg-yellow-50'>
            <button
              type={"button"}
              onClick={() => handleTabs(1)}
              className={`${getTabs === 1 ? "bg-yellow-500 text-white font-semibold " : " bg-white text-gray-600 "} cursor-pointer border py-1 px-2
              flex items-center whitespace-nowrap overflow-hidden -ml-1 w-20 text-center overflow-ellipsis lg:max-w-sm 2xl:max-w-md`} style={{ minWidth: "48px" }}>
              <span className=" whitespace-nowrap overflow-hidden overflow-ellipsis text-center w-full font-medium text-sm">
                English </span>
            </button>
            <button
              type={"button"}
              onClick={() => handleTabs(0)}
              className={`${getTabs === 0 ? "bg-yellow-500 text-white font-semibold " : " bg-white text-gray-600 "} cursor-pointer border py-1 px-2
              flex items-center whitespace-nowrap overflow-hidden -ml-1 w-20 text-center overflow-ellipsis lg:max-w-sm 2xl:max-w-md`} style={{ minWidth: "48px" }}>
              <span className=" whitespace-nowrap overflow-hidden overflow-ellipsis text-center w-full font-medium text-sm">
                தமிழ் </span>
            </button>
            <button
              type={"button"}
              onClick={() => handleTabs(2)}
              className={`${getTabs === 2 ? "bg-yellow-500 text-white font-semibold " : " bg-white text-gray-600 "} cursor-pointer border py-1 px-2
              flex items-center whitespace-nowrap overflow-hidden -ml-1 w-20 text-center overflow-ellipsis lg:max-w-sm 2xl:max-w-md`} style={{ minWidth: "48px" }}>
              <span className=" whitespace-nowrap overflow-hidden overflow-ellipsis text-center w-full font-medium text-sm">
                తెలుగు </span>
            </button>
            <button
              type={"button"}
              onClick={() => handleTabs(3)}
              className={`${getTabs === 3 ? "bg-yellow-500 text-white font-semibold " : " bg-white text-gray-600 "} cursor-pointer border py-1 px-2
              flex items-center whitespace-nowrap overflow-hidden -ml-1 w-20 text-center overflow-ellipsis lg:max-w-sm 2xl:max-w-md`} style={{ minWidth: "48px" }}>
              <span className=" whitespace-nowrap overflow-hidden overflow-ellipsis text-center w-full font-medium text-sm">
                हिंदी </span>
            </button>
          </div>
          <FormTemplate
            state={getTabs}
            getUrlPoint={getUrlPoint}
            languageInfo={languageInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerFeedback;
