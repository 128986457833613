import axios from "axios";
import { _get } from "../../utils/lodashConst";

const Noheaders = {
  "Content-Type": "application/json",
};

/**
 * @param url this methos help to call api without header
 */
export const withoutTokenGet = async (url, header = {}) => {
  try {
    return await axios.get(url, { headers: Object.keys(header).length > 0 ? { ...header } : { ...Noheaders } });
  } catch (error) {
    return _get(error, "response", {});
  }
};

/**
 * @param url this methos help to call api without header
 */
export const withoutTokenPost = async (
  url = "",
  params = {},
  header = {}
) => {
  try {
    return await axios.post(url, params, { headers: Object.keys(header).length > 0 ? { ...header } : { ...Noheaders } });
  } catch (error) {
    return _get(error, "response", {});
  }
};

/**
 * @param url this methos help to call api without header
 */
export const withoutTokenPut = async (
  url = "",
  params = {},
  header = {}
) => {
  try {
    return await axios.put(url, params, { headers: Object.keys(header).length > 0 ? { ...header } : { ...Noheaders } });
  } catch (error) {
    return _get(error, "response", {});
  }
};
