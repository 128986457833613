import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { nullToObj } from '../utils/validation';

const CommonRadio = (props = {}) => {
    const {
        id = 0,
        key = 0,
        name = "",
        children,
        value = "",
        label = true,
        labelText = "",
        setShow = true,
        parentClass = "",
        readOnly = false,
        defaultValue = "",
        checked = false,
        customLabel = false,
        onClick = () => { },
        onChange = () => { },
        parentAsLabel = false,
        defaultChecked = false,
        parentAsLabelClass = "",
    } = nullToObj(props);

    const Checktemplate = () => {
        return (
            <Fragment>

                <Transition
                    show={setShow}
                    leaveTo="opacity-0"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leaveFrom="opacity-10"
                    enter="transition-opacity duration-200"
                    leave="transition-opacity duration-200"
                    className={`form-check ${parentClass ? parentClass : ""}`}
                >
                    <input
                        key={key}
                        name={name}
                        type="radio"
                        value={value}
                        id={`check-${id}`}
                        readOnly={readOnly}
                        // defaultValue={defaultValue}
                        defaultChecked={defaultChecked}
                        className="form-check-input mr-2"
                        onChange={defaultValue ? null : onChange}
                        onClick={onClick}
                        checked={defaultValue ? undefined : checked}
                    />

                    {label &&
                        <label
                            htmlFor={`check-${id}`}
                            className="form-check-label pl-2 cursor-pointer text-xs capitalize font-normal"
                        >
                            {labelText}
                        </label>
                    }
                </Transition>

                {customLabel &&
                    <label
                        htmlFor={`check-${id}`}
                        className="form-check-label cursor-pointer ml-2  text-xs  font-normal"
                    >
                        {labelText}
                    </label>
                }
                {children}
            </Fragment>);
    };

    return (
        <React.Fragment>
            {
                parentAsLabel ?
                    <label
                        tabIndex={0}
                        role="button"
                        htmlFor={`check-${id}`}
                        className={`${parentAsLabelClass ? parentAsLabelClass : ""} cursor-pointer`}
                    >
                        {Checktemplate()}
                    </label> :
                    <React.Fragment>
                        {Checktemplate()}
                    </React.Fragment>
            }
        </React.Fragment>
    );
};

export default React.memo(CommonRadio);
