import toastService from "./index";

export const infoToast = (successMsg = "", informToastId = "") => {
    toastService.infoToast(
        <div className="customToast">
            <i className="info">
            </i>
            <p>{successMsg}</p>
        </div>, informToastId
    );
};

export const failToast = (failMsg = "", failToastId = "") => {
    toastService.errorToast(
        <div className="customToast">
            <i className="info">
            </i>
            <p>{failMsg}</p>
        </div>, failToastId
    );
};

export const copyToast = (cpyMsg, toastId = "") => {
    toastService.copyToast(
        <div className="customToast">
            <i className="info">
            </i>
            <p>{cpyMsg}</p>
        </div>, toastId
    );
};

export const succToast = (Msg, successtoastId = "") => {
    toastService.successToast(
        <div className="customToast">
            <i className="info">
            </i>
            <p>{Msg ? Msg : ""}</p>
        </div>, successtoastId
    );
};
