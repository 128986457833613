import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { IconError, IconSearchCustomer } from '../assets/images';
import { nullToObj } from '../utils/validation';

const CommonTextarea = (props) => {
    const {
        children,
        name = "",
        value = "",
        label = true,
        labelTextClass,
        labelText = "",
        setShow = false,
        inputClass = "",
        placeholder = "",
        parentClass = "",
        readOnly = false,
        mustField = false,
        inputErrorMsg = "",
        inputError = false,
        inputvalid = false,
        ErrorMsgClass = "",
        onBlur = () => { },
        onChange = () => { },
        inputFieldClass = "",
        onBlurChange = false,
        maxLength = 10000000,
        inputWithIcon = false,
        inputWithIconClass = "",
    } = nullToObj(props);

    const [valueInput, setValueInput] = useState("");

    const handleOnchange = (event = {}) => {
        const { value: aliceValue = "", name: aliceName = "" } = event.target;
        setValueInput(aliceValue);
        if (!onBlurChange) { //onChange not trigger true means not working on change
            event.preventDefault && event.preventDefault();
            onChange({
                ...event,
                target: {
                    name: aliceName,
                    value: aliceValue,
                },
            });
        }
    };

    const onBlurHandle = (event = {}) => {
        if (onBlurChange) {
            event.preventDefault && event.preventDefault();
            const { value: aliceValue = "", name: aliceName = "" } = nullToObj(event.target);
            setValueInput(aliceValue);
            onChange({
                ...event,
                target: {
                    name: aliceName,
                    value: aliceValue,
                },
            });
            onBlur(event);
        } else {
            onBlur(event);
        }
    };

    useEffect(() => {
        setValueInput(value);
    }, [value]);

    return (
        <Transition
            show={setShow}
            leaveTo="opacity-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leaveFrom="opacity-10"
            enter="transition-opacity duration-200"
            leave="transition-opacity duration-200"
            className={`form-group relative ${parentClass ? parentClass : ""}`}
        >
            {label &&
                <label
                    className={`block text-xs input-label mb-1 
                    ${labelTextClass ? labelTextClass : ""}
                    ${inputError && !inputvalid ? " text-error" : ""}
                    ${inputvalid && !inputError ? " text-valid" : ""}`}
                    htmlFor=""
                >
                    {labelText} {mustField && <sup style={{ color: "red" }}>*</sup>}
                </label>
            }
            <div
                className={`
                ${inputClass ? inputClass : ""}
                ${inputWithIcon ? inputWithIcon : ""}
                relative`}
            >
                <textarea
                    name={name}
                    value={valueInput}
                    autoComplete={"off"}
                    readOnly={readOnly}
                    id="userTypingInput"
                    onChange={(e) => handleOnchange(e)}
                    onBlur={onBlur ? onBlurHandle : () => { }}
                    maxLength={maxLength ? maxLength : 100000}
                    placeholder={placeholder ? placeholder : ""}
                    className={`block w-full border-yellow-400 border rounded input-common px-3 text-sm py-1 ${inputError && !inputvalid ? " error pr-10" : ""}
                    ${inputvalid && !inputError ? " input-valid" : ""} ${inputFieldClass ? inputFieldClass : ""}
                `}
                />
                {inputError && !inputvalid &&
                    <i
                        className="inline-block  absolute top-1/2 right-3 transform -translate-y-1/2">
                        <IconError />
                    </i>}
                {inputWithIcon &&
                    <span
                        className={`absolute flex justify-center items-center ${inputWithIconClass ? inputWithIconClass : ""}`}>
                        <IconSearchCustomer />
                    </span>
                }
            </div>
            {inputError && !inputvalid && inputErrorMsg &&
                <span
                    className={`absolute -bottom-5 right-5 error-msg text-xs ${ErrorMsgClass ? ErrorMsgClass : ""}`}>
                    {inputErrorMsg}
                </span>
            }
            {children}
        </Transition>
    );
};

export default React.memo(CommonTextarea);
