import React from 'react';
import CustomerFeedback from './CustomerFeedback';
import ToastContainerJSX from '../../Common/ToastServices/ToastContainerJSX';
import {
  BrowserRouter as Router,
} from "react-router-dom";

const CustomerForm = () => {

  return (
    <>
      {/* don't delete */}
      <ToastContainerJSX />
      {/* don't delete*/}

      <Router>
        <CustomerFeedback />
      </Router>
    </>
  );
};

export default CustomerForm;
