export const REACT_APP_ENVIRONMENT = "prod"; //environment
export const REACT_APP_ORG_API_URL = "https://poorvika.org/"; //org base path
export const REACT_APP_ECOM_BASE_PATH = "https://poorvika.com/"; //ecomPath
export const REACT_APP_PIM_URL = "https://api-prod.poorvika.com/";
export const REACT_APP_APX_URL = " https://apx.poorvikamobiles.net/"; //apx
export const REACT_APP_CRM_BASE_URL = "https://bcrm.poorvika.com/api/";
export const REACT_APP_PRODUCT_VIEW = "https://apig-pim.poorvika.com/";//get product info
export const REACT_APP_TYPE_SENSE_KEY = "YsItgfKl6Oi2Mj3TtiAZPqoTckK2tiHn";
export const REACT_APP_API_POORVIKA_BASE_PATH = "https://api.poorvika.com/";
export const REACT_APP_OFFER_API = "https://apin-offers-coupons.poorvika.com"; //offer api call
export const REACT_APP_SECRET_KEY = "12435667-jK67G-jK67G-Ok78H-rtBH67KLLM67";
export const REACT_NO_IMAGE = "https://img.poorvika.com//NoImageAvailable.jpg"; //NO IMAGE
export const REACT_APP_OFFER_API_TOKEN = "b5334cd1-b0e1-4e87-983f-a610c336c754"; //offer api TOKEN
export const REACT_APP_CRM_BASE_URL_WITH_OUT_API = "https://bcrm.poorvika.com/";
export const REACT_APP_TYPE_SENSE = "https://3hskuwtianmdbqoep.a1.typesense.net/"; //typesense api
export const REACT_APP_URL_OFFER_PROJECT_BASE_URL = "https://offer.poorvika.com/";
export const REACT_APP_CENTRAL_INVENTORY_API_PATH = "https://api-centralinventory.poorvika.com/";
export const REACT_APP_EXOTEL_API_URI_PATH = "https://api.exotel.com/v1/Accounts/poorvika1/Calls/connect.json";
export const REACT_APP_PLAY_STORE_URI_PATH = "https://play.google.com/store/apps/details?id=com.pmpl_crm_mobile_app";
